
button.likelink {
     background:none;
     color:#3366BB;
     border:none;
     padding:0!important;
     font: inherit;
     /*border is optional
     border-bottom:1px solid #444;
     cursor: pointer;*/
}

p.error {
  color:red;
}

button.tab {
  background-color: inherit;
  float: left;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid #a5a5a5;
  margin-right: 1px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  color: #3366BB;
  height: 35px;
  outline: none;
  cursor: pointer;
  padding: 10px 10px;
  transition: 0.3s;
}

button.tabactive {
  background-color: #a5a5a5;
  float: left;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid #a5a5a5;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: 35px;
  margin-right: 1px;
  outline: none;
  cursor: pointer;
  padding: 10px 10px;
  transition: 0.3s;
}

.containerborder {
  background-color: #f2f2f2;
  border: 1px solid #a5a5a5;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  padding: 5px;
}

.replycontainer {
  background-color: #ebccff;
  padding-left: 10px;
}

.Countdown-col {
  display: inline-block;
}

.Countdown-col-element {
  display: inline-block;
  border: 1px solid black;
  padding: 5px;
}


.nonbsinput {
  height: 31px;
}

.head {
   white-space: pre-wrap;      /* CSS3 */
   white-space: -moz-pre-wrap; /* Firefox */
   white-space: -pre-wrap;     /* Opera <7 */
   white-space: -o-pre-wrap;   /* Opera 7 */
   word-wrap: break-word;      /* IE */
   font-weight: bold;
   font-size: 9pt;
}

.tablecol {
  white-space: pre-wrap;      /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap;     /* Opera <7 */
  white-space: -o-pre-wrap;   /* Opera 7 */
  word-wrap: break-word;      /* IE */
  font-size: 9pt;
}

p.bigcolon {
  display: inline-block;
  font-size: 75px;
}

.nonewline {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 1;
}

.maincontainer {
  background-color: white;
  border: 1px solid black;
  border-radius: 25px;
  max-height: 100%;
}

.pagecontainer {
  background-color: white;
  border-radius: 25px;
  height: 100%;
  margin-top: 10px;
}

.fixed {
  position:fixed;
}

#clockimg {
  color: black;
}

.innerrow {
  background-color: #ebccff;
  /*background-color: #9996ff;*/
  margin-left: 10px;
  margin-right: 10px;
  padding:5px;
  border: 1px solid black;
  border-radius: 25px;
}

.buttonsize {
  width: 150px;
}

div.scrollbox {
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.spaceleftright {
  margin-left: 5px;
  margin-right: 5px;
}

.toprow {
  background-color: #464570;
  /*background-color: #9996ff;*/
  border-bottom: 1px solid black;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.boxtext {
  color: white;
  padding: 10px;
}

div.spacewidth {
  display: inline-block;
  width: 50px;
}

div.whitespace {
  color: white;
  height: 10px;
}

.navactive {
  color:yellow;
}

.navstyle {
  color: white;
  /*color:#3366BB;*/
}

.listitemstyle {
  list-style-type: none;
}

#catsvg {
  width:80px;
  height:120px;
}

#catpath {
  fill:#464570;
}

div#catdiv {
  width:80px;
  height:120px;
  display:inline-block;
  padding-left: 40px;
}

.titletext {
  color: #464570;
  font-style: normal;
  font-weight: bold;
  font-family: sans-serif;
  font-style: normal;
  font-size: 40pt;
}

.menu {
  background-color: #464570;
  border: 1px;
  border-radius: 25px;
  margin-left: 10px;
}

.grouprow {
  padding-bottom: 1px;
}

.zenerpanel {
  background-color: #017a07;
  width: 800px;
  height:600px;
  padding-left: 10px;
}

.card {
  background-color: #017a07;
  width:108px;
  height:175px;
}

.card-md {
  width:54px;
  height:87px;
}

.card-disabled {
  background-color: #017a07;
  width:108px;
  height:175px;
  opacity: 0.5;
}

.resultpanel {
  overflow:auto;
  overflow-x:hidden;
  height:170px;
}

.iconsmall {
  width:20px;
  height:20px;
}

.message-text {
  color: #cf6604;
}

.message-box {
  border-radius: 25px;
  border-width: 2px;
  border-color: black;
  border-style: solid;
  background-color: #5ef75c;
  margin: 5px;
  padding: 5px;
}

.name-text {
  color: #cf6604;
  text-align: center;
  border-radius: 25px;
  border-width: 2px;
  border-color: black;
  border-style: solid;
  background-color: #5ef75c;
  margin-left: 10%;
  margin-right: 10%;
}

.wait-anim {
  width:200px;
  height:200px;
}

/*
DOESENT WORK
.wait-anim {
  height: 90px;
  width: 90px;
  background-image: url("/static/media/brokencircleBl.499a011e.svg");
}

@keyframes waiting {
  0% {
    background-image: url("/static/media/brokencircleBl.499a011e.svg");
  }
  25% {
    background-image: url("./assets/brokencircleBl2.svg");
  }
  50% {
    background-image: url("./assets/brokencircleBl3.svg");
  }
  75% {
    background-image: url("./assets/brokencircleBl4.svg");
  }
}*/

.citation {
  font-size: 12px;
  font-family: serif;
  font-style: italic;
  font-weight: bold;
}

.spin-box {
  width: 6em;
}
